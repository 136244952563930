<template>
  <div class="report-improve" v-wechat-title="`${$route.meta.title}_京蒙教育协作智慧平台`">
    <div class="report-improve-wrapper manage-wrapper">
      <div class="improve-filter">
        <div class="filter-row flex-layout">
          <span class="filter-row-name">分类：</span>
          <div class="filter-row-ul-wrapper">
            <ul class="filter-row-ul">
              <li class="filter-row-item csp" v-for="(item,index) in typeList" :key="index" @click="changeType(item)"
                :class="{'active':item.id === activeType}">{{item.name}}</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="school-list">
        <ul class="flex-align card-ul">
          <li v-for="(item, index) in list" :key="index" class="card csp" @click="goPage(item,index)">
            <div class="status flex-align-between">
              <!-- 我的课程 -->
              <div class="kind">
                <img class="course-type-image" :src="kindMap[item.bindType]" alt="" />
              </div>
            </div>
            <div class="img-wrapper">
              <!-- 作业 -->
              <img v-if="item.bindType===0" :src="downloadURL + item.cover"
                :onerror="$store.state.course.defaultTaskCover" alt />
              <!-- 课程 -->
              <img v-if="item.bindType===5" :src="downloadURL + item.cover"
                :onerror="$store.state.course.defaultCourseCover[index % 6]" alt />
              <!-- 活动 -->
              <img v-if="item.bindType===6" :src="downloadURL + item.cover"
                :onerror="$store.state.course.defaultActivityCover" alt />
              <!-- 资源 -->
              <img v-if="item.bindType===7"
                :src="item.cover ? downloadURL + item.cover : item.extension ? $store.state.resource.defaultResourceCoverMap[item.extension] :$store.state.resource.unknowResourceCover"
                alt />
            </div>
            <div class="flex-column-between info">
              <h3 class="title wes-2">{{ item.title || '无'}}</h3>
              <!-- 我的课程 -->
              <div>
                <!-- <div class="speaker">主讲老师：{{item.teacher || '未知'}}</div> -->
                <div class="time">
                  上课时间：{{ (item.biginTime && dayjs(item.beginTime*1000).format('YYYY-MM-DD HH:mm'))|| '--'}}
                </div>
              </div>
            </div>
          </li>
        </ul>
        <!-- <div class="page-box" v-if="list.length > 0 && list"> -->
        <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
          :limit.sync="pages.pageSize" :pageSizes="[8,16,24]" @pagination="handlePageChange">
        </pagination>
        <!-- </div> -->
        <!-- 无课节-->
        <Empty :show="list && list.length === 0"></Empty>
      </div>


      <div class="course">
        <ul class="course-ul">
          <!-- <li v-for="(item, index) in filelist" :key="index" :class="calcStyle">
          <div class="course_box_top flex-align-between">
            <div class="box_left">{{item.filename }}
            </div>
          </div>
          <img class="filelistimg" :src="item.url" :onerror="defualtImage[(index + 1) % 5]" alt />
          <div class="flex-column-between info">
            <h3 class="course_name wes-2">{{ item.name }}</h3>
            <div class="course_speaker">上传者： {{item.uploader}}</div>
            <div class="course_speaker course_time">
              预览量：{{item.views}}
            </div>
            <div class="flex-align-between other-msg">
              <span class="flex-align other_left"></span>
              <span class="button">查看</span>
            </div>
          </div>
        </li> -->


          <!-- <li v-for="(item, index) in activelist" :key="index+5" :class="calcStyle">
          <div class="course_box_top flex-align-between">
            <div class="box_left">{{item.filename }}
            </div>
          </div>
          <img :src="item.url" :onerror="defualtImage[(index + 1) % 5]" alt />
          <div class="flex-column-between info">
            <h3 class="course_name wes-2">{{ item.name }}</h3>
            <div class="course_speaker">{{item.active}}：{{item.time}}</div>
            <div class="course_speaker course_time">
              积分：{{item.jifen}}
            </div>
            <div class="flex-align-between other-msg">
              <span class="flex-align other_left"></span>
              <span class="button">查看</span>
            </div>
          </div>
        </li> -->


          <!-- <li v-for="(item, index) in list" :key="index" :class="calcStyle">
            <div class="course_box_top flex-align-between">
              <div class="box_left">
                {{item.lessonsPattern == 0? "同步课": item.lessonsPattern == 1? "异步课": ""}}
              </div>
            </div>
            <img :src=" item.cover ? downloadURL + item.cover : defualtImage[index%5] " alt
              v-if="item.bindType === 5" />
            <img
              :src=" item.cover ? downloadURL + item.cover : `${require('@/assets/images/course/activity-cover.png')}` "
              alt v-if="item.bindType === 6" />
            <img :src=" item.cover ? downloadURL + item.cover : `${require('@/assets/images/course/task-cover.png')}` "
              alt v-if="item.bindType === 0" />
            <img
              :src=" item.cover ? downloadURL + item.cover : item.extension ? coverMap[item.extension] :`${require('@/assets/images/default-cover.png')}`"
              alt v-if="item.bindType === 7" />
            <div class="flex-column-between info">
              <h3 class="course_name wes-2">{{ item.title }}</h3>
              <div class="course_speaker">主讲课程：{{ item.teacherName }}</div>
              <div class="course_speaker course_time">
                {{item.time}}
              </div>

              <div class="flex-align-between other-msg">
                <span class="flex-align other_left"></span>
                <span class="button csp" @click="goPage(item,index)">查看</span>
                @click="goPage(item.id, index)"
              </div>
            </div>
          </li> -->
        </ul>
        <!-- <div class="page-box" v-if="list.length > 0 && list"> -->
        <!-- <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
          :limit.sync="pages.pageSize" :pageSizes="[8,16,24]" @pagination="handlePageChange">
        </pagination> -->
        <!-- </div> -->
        <!-- 无课节-->
        <!-- <Empty :show="list && list.length === 0"></Empty> -->

      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
  export default {
    data() {
      return {
        pages: { //分页
          currentPage: 1,
          pageSize: 8,
          total: 0,
        },
        activeType: 100,
        typeList: [{
            name: '全部',
            id: 100,
          },
          {
            name: '作业',
            id: 0,
          },
          {
            name: '课程',
            id: 5
          },
          {
            name: '活动',
            id: 6
          },
          {
            name: '资源',
            id: 7
          },
        ],
        kindMap: {
          0: require("../../assets/images/ability/label-work.png"),
          5: require("../../assets/images/ability/label-course.png"),
          6: require("../../assets/images/ability/label-activity.png"),
          7: require("../../assets/images/ability/label-resource.png"),
        },
        // defualtImage: [
        //   require("../../assets/images/course/course-cover01.jpg"),
        //   require("../../assets/images/course/course-cover02.jpg"),
        //   require("../../assets/images/course/course-cover03.jpg"),
        //   require("../../assets/images/course/course-cover04.jpg"),
        //   require("../../assets/images/course/course-cover05.jpg")
        // ],
        // coverMap: {
        //   'mp3': require("@/assets/images/resource/mp3.png"),
        //   'mov': require("@/assets/images/resource/mp4.png"),
        //   'mp4': require("@/assets/images/resource/mp4.png"),
        //   'pdf': require("@/assets/images/resource/pdf.png"),
        //   'ppt': require("@/assets/images/resource/ppt.png"),
        //   'pptx': require("@/assets/images/resource/ppt.png"),
        //   'doc': require("@/assets/images/resource/word.png"),
        //   'docx': require("@/assets/images/resource/word.png"),
        //   'xls': require("@/assets/images/resource/ex.png"),
        //   'xlsx': require("@/assets/images/resource/ex.png"),
        // },
        list: [],
      };
    },
    methods: {
      changeType(item) {
        this.activeType = item.id;
        this.pages.currentPage = 1
        this.getAbilityPromote();
      },
      // 翻页
      handlePageChange(data) {
        this.pages.currentPage = data.pageIndex
        this.pages.pageSize = data.pageSize
        // console.log(this.pages);
        this.getAbilityPromote();
      },
      //跳转页面
      goPage(item, index) {
        console.log(item, index);
        /** 0 作业 5课程 6活动 7资源 */
        if (item.bindType === 7) {
          this.common.preview(item);
          return
        }
        let breadListLength = this.$store.state.basics.breadList.length
        let map = {
          0: {
            path: "/mytask/detail/",
            query: {
              breadNumber: breadListLength + 1
            },
          },
          5: {
            path: "/course/",
            query: {
              index: index,
              breadNumber: breadListLength + 1
            },
          },
          6: {
            path: "/myActivity/detail/",
            query: {
              breadNumber: breadListLength + 1
            },
          },
        }

        this.$router.push({
          path: `${map[item.bindType].path}${item.bindId}`,
          query: map[item.bindType].query
        });

      },
      async getAbilityPromote() {
        let pamars = {
          abilityId: this.$route.query.abilityId,
          page: this.pages.currentPage,
          limit: this.pages.pageSize,
        }
        pamars.type = this.activeType == '100' ? null : this.activeType;
        let resData = await this.$Api.DST.getAbilityPromote(pamars);
        console.log(resData);
        this.list = [];
        this.list = resData.data.records;
        this.pages.total = resData.data.total;
      },
    },
    mounted() {
      this.getAbilityPromote();
      // this.getFileToken();
      scrollTo(0, 0);
      this.$route.meta.title = `${this.$route.query.title}提升`;
    },
  };
</script>

<style lang="less" scoped>
  .report-improve {

    // min-height: 100vh;

    // .report-improve-wrapper {
    //   flex: 1;
    // }

    .improve-filter {
      margin: 0 0 22px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0px 2px 10px 0px #F5F8FA;


      .filter-row {
        padding-left: 32px;
      }

      .filter-row-name {
        min-width: 60px;
        line-height: 50px;
        color: #555;
      }

      .filter-row-ul {
        flex-grow: 1;
      }

      .filter-row-item {
        display: inline-block;
        // margin: 0 10px 0px 0;
        padding: 0 25px;
        line-height: 50px;
        color: #999;

        &.active {
          color: #508EF9;
        }
      }
    }

    // 课程列表
    .school-list {
      margin-top: 25px;

      .card-ul {
        flex-flow: row wrap;

        .card {
          position: relative;
          box-sizing: border-box;
          margin: 0 11px 22px 0;
          border-radius: 10px;
          padding: 0 0 10px 0;
          max-width: 239px;
          width: 24.5%;
          background-color: #fff;

          &:nth-child(4n) {
            margin-right: 0px;
          }
        }

        //li上部分
        .status {
          box-sizing: border-box;

          .kind {
            position: absolute;
            top: 0;
            left: -2px;

            .course-type-image {
              width: 50px;
            }
          }

          // .kind-type {
          //   position: absolute;
          //   top: 14px;
          //   left: 0;

          //   text-align: center;
          //   line-height: 24px;
          //   font-size: 14px;
          //   padding: 2px 12px 2px 7px;
          //   height: 24px;
          //   border-radius: 2px 12px 12px 2px;
          //   color: #ffffff;
          //   // color: #2474ec;
          // }

          // .background-color-1 {
          //   background: #F6B926;
          // }

          // .background-color-2 {
          //   background: #36C2CF;
          // }

          // .background-color-3 {
          //   background: #EF5757;
          // }

          // .countdown {
          //   color: #ff1a1a;
          // }
        }

        //中间图片
        .img-wrapper {
          width: 100%;
          height: 128px;
          margin: 0px auto 5px;
          overflow: hidden;
          border-radius: 3px;

          img {
            width: 100%;
            max-width: 100%;
            border-radius: 8px;
          }
        }


        //li下部分
        h3.title {
          margin-bottom: 5px;
          height: 38px;
          font-size: 13px;
          padding: 0 11px;
          font-weight: bold;
        }


        .speaker,
        .integral,
        .time {
          padding-bottom: 3px;
          color: #595959;
          font-size: 12px;
          padding: 0 11px;
          line-height: 20px;
        }

        .other {
          margin-top: 10px;

          .otherJob {
            font-size: 15px;
            color: #888;
          }

          .toDetail {
            max-width: 119px;
            padding: 0 32px;
            text-align: center;
            background: linear-gradient(90deg, #2272ec, #3a7bf6);
            box-shadow: 0px 5px 13px 0px rgba(22, 115, 255, 0.28);
            border-radius: 5px;
            line-height: 31px;
            color: #ffffff;
          }
        }
      }

      @media screen and (min-width: 1250px) {
        .card-list {
          padding: 20px 22px;
        }

        .card-ul {
          .card {
            margin: 0 13px 29px 0;
            padding: 0 0 15px 0;
            max-width: 290px;
          }

          .img-wrapper {
            height: 155px;
            margin: 0px auto 10px;
          }

          h3.title {
            margin-bottom: 10px;
            font-size: 15px;
            height: 44px;
          }
        }
      }
    }

    .course {
      // width: 1200px;
      border-radius: 10px;
      // background: #ffffff;
      // padding: 0 70px;
      // margin: 20px auto 0;

      .screening {
        width: 100%;
        margin: 0 auto;
        padding: 10px 0;
        padding-bottom: 20px;
        background: #fff;

        // border:1px solid red;
        .public {
          display: flex;
          cursor: pointer;
          // align-items: center;
          // justify-content: center;
          margin-bottom: 20px;

          .public_left {
            padding-top: 3px;
            font-size: 14px;
            width: 60px;
            text-align: center;
          }

          ul {
            flex: 1;

            li {
              font-size: 14px;
              padding: 3px 20px;
              display: inline-block;
              border-radius: 20px;
            }

            .active {
              background: #2373ec;
              color: #ffffff;
            }
          }
        }

        .classify {
          // padding:20px;
        }

        .format {
          // padding:20px;

        }
      }

      .course-ul {
        display: flex;
        flex-flow: row wrap;
        align-content: flex-start;

        .filelistimg {
          width: 324px;
          height: 197px;
        }

        .u-lof4 {
          flex: 0 0 24%;
        }

        .u-lof3 {
          flex: 0 0 29%;
        }

        .u-lof2 {
          flex: 0 0 30%;
        }

        li {
          margin-right: 40px;
          margin-bottom: 20px;
          box-sizing: border-box;
          padding: 20px 18px;
          background: #ffffff;
          // box-shadow: 0px 5px 29px 0px rgba(22, 115, 255, 0.16);
          border-radius: 10px;
        }

        .course_box_top {
          box-sizing: border-box;
          padding-bottom: 10px;

          .box_left {
            border-left: 4px solid #2474ec;
            padding-left: 10px;
            color: #2474ec;
            font-weight: 400;
            font-size: 15px;
          }

          .icon_right {
            vertical-align: middle;
            display: inline-table;
            width: 16px;
            height: 16px;
            margin-left: 5px;
            background-image: url("../../assets/images/home/icon_time.png");
            background-repeat: no-repeat;
          }

          .box_right {
            color: #ff1a1a;
            font-weight: 300;
            font-size: 14px;
          }
        }

        h3 {
          font-size: 18px;
          height: 50px;
          font-weight: bold;
          color: #333333;
          margin-bottom: 12px;
        }

        img {
          display: block;
          margin-bottom: 10px;
          border-radius: 3px;
          background-color: #1c4b90;
          width: 100%;
        }

        .info {
          min-height: 82px;

          //   .course_name {
          //   }
          .course_speaker {
            color: #595959;
            font-size: 15px;
            font-weight: 400;
            padding-bottom: 3px;
          }

          //   .course_time {
          //   }
          .course_speed {
            padding-top: 3px;
            padding-bottom: 10px;
          }
        }

        .other-msg {
          font-size: 12px;
          color: #999;

          .other_left {
            font-size: 15px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #888888;
          }

          .button {
            width: 119px;
            height: 31px;
            text-align: center;
            background: linear-gradient(90deg, #2272ec, #3a7bf6);
            box-shadow: 0px 5px 13px 0px rgba(22, 115, 255, 0.28);
            border-radius: 5px;
            font-size: 14px;
            font-family: PingFang SC;
            line-height: 31px;
            color: #ffffff;
          }

          /*
    //   .time::before {
    //     content: " ";
    //     margin-right: 4px;
    //     width: 20px;
    //     height: 20px;
    //     background-image: url("../../../../static/news/time@2x.png");
    //     background-repeat: no-repeat;
    //   }*/
        }
      }

      .page-box {
        text-align: right;
        margin-right: 20px;
        padding-bottom: 20px;
      }
    }
  }
</style>